import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import AddFormApi from "./AddFormApi";
import UpdateFormApi from "./UpdateFormApi";

function WebsiteLeads() {
  const [websiteleads, setWebsiteLeads] = useState([]);
  const [websiteApi, setWebsiteApi] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [websiteleadsAssigned, setwebsiteLeadsAssigned] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [showWebsite, setShowWebsiteApi] = useState(false);  
  const [showUpdateWebsiteApi, setShowUpdateWebsiteApi] = useState(false);  
  const [refreshLeads, setRefreshLeads] = useState(false);  // State to trigger refresh

  const [currentLead, setCurrentLead] = useState({
    assignedTo: "",
    employeeId: "",
    employeephone: "",
    createdTime:"",
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(0);
  const [leadsPerPage] = useState(10);

 
    const fetchApiData = async () => {
    try {
      const response = await axios.get(
        `https://crmdemo.vimubds5.a2hosted.com/api/get-website-api`
      );
      setWebsiteApi(response.data[0].api);
      console.log(response);
    } catch (error) {
      console.error("Error fetching form:", error);
    }
  };
  const WebsiteApiUrl = websiteApi
  console.log(WebsiteApiUrl);
  

  const fetchLeads = async () => {
    try {
      const response = await axios.get(WebsiteApiUrl);
      if (Array.isArray(response.data)) {
        setWebsiteLeads(response.data);
      } else {
        console.error("Unexpected data format", response.data);
      }
    } catch (error) {
      console.error("Error fetching website leads:", error);
    }
  };
  

  // Fetch employees
  const fetchEmployees = async () => {
    try {
      const response = await axios.get("https://crmdemo.vimubds5.a2hosted.com/api/employee");
      setEmployees(response.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  // Fetch lead assignments
  const fetchLeadassigned = async () => {
    try {
      const response = await axios.get("https://crmdemo.vimubds5.a2hosted.com/api/leads");
      setwebsiteLeadsAssigned(response.data);
    } catch (error) {
      console.error("Error fetching assigned leads:", error);
    }
  };

  useEffect(() => {
        fetchLeads();
    fetchApiData();
    fetchEmployees();
    fetchLeadassigned();
  }, []);
  useEffect(() => {
    fetchLeads();

  }, [websiteApi]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentLead((prevLead) => {
      const updatedLead = { ...prevLead, [name]: value };
  
      // If assignedTo changes, update employeeId and employeephone accordingly
      if (name === "assignedTo") {
        const selectedEmployee = employees.find(
          (employee) => employee.name === value
        );
        if (selectedEmployee) {
          updatedLead.employeeId = selectedEmployee.employeeId;
          updatedLead.employeephone = selectedEmployee.phone; // Store employee's phone number in employeephone
        } else {
          updatedLead.employeeId = ""; // Reset if no match
          updatedLead.employeephone = ""; // Reset employeephone if no match
        }
      }
  
      return updatedLead;
    });
  };
  
  const saveChanges = async () => {
    // Check if assignedTo field is empty
    if (!currentLead.assignedTo) {
      alert("Please assign the lead to an employee."); // Show an alert message
      return; // Stop further execution if the field is empty
    }
    if (!currentLead.createdTime) {
      alert("Please Select Assign Date."); // Show an alert message
      return; // Stop further execution if the field is empty
    }
  
    try {
      await axios.post("https://crmdemo.vimubds5.a2hosted.com/api/leads", {
        lead_no: selectedLead.leadId,
        assignedTo: currentLead.assignedTo,
        employeeId: currentLead.employeeId,
        createdTime:  currentLead.createdTime,
        actual_date:  selectedLead.date,
        name: selectedLead.fullName,
        phone: selectedLead.phoneNumber,
        leadSource: "Website",
        subject: selectedLead.subject,
        address: selectedLead.address,
      });
      fetchLeads();
      fetchLeadassigned();
        // Reset form data
    setCurrentLead({
      assignedTo: '',
      employeeId: '',
      createdTime: '',
     
      // Add other fields here if needed
    });
    setSelectedLead({
      leadId: '',
      date: '',
      fullName: '',
      phoneNumber: '',
      address: '',
      // Add other fields here if needed
    });
      closePopup();

      // Format the createdTime using moment
const formattedDate = moment(currentLead.createdTime).format("DD-MM-YYYY"); // Format the date as 'DD-MM-YYYY'

// Generate the WhatsApp link with the formatted date
const whatsappLink = `https://wa.me/${currentLead.employeephone}?text=Hi%20${currentLead.assignedTo},%20you%20have%20been%20assigned%20a%20new%20lead%20with%20the%20following%20details:%0A%0A1)%20Date:-${formattedDate}%0A2)%20Lead%20No.%20${selectedLead.leadId}%0A3)%20Name:%20${selectedLead.fullName}%0A4)%20Phone%20Number:%20${selectedLead.phoneNumber}%0A5)%20Lead%20Source:%20One%20Realty%20Website%0A6)%20Address:%20${selectedLead.address}%0A7)%20Subject:%20${selectedLead.subject}%0A%0APlease%20check%20your%20dashboard%20for%20details.`;

// Open WhatsApp link
window.open(whatsappLink, "_blank");

    } catch (error) {
      console.error("Error adding lead:", error);
    }
  };
  
  const handleEditClick = (lead) => {
    setSelectedLead({
      leadId: lead.id,
      fullName: lead.name,
      phoneNumber: lead.mobile_no,
      subject: lead.subject,
      address: lead.address,
      date: moment(lead.created_date).format("YYYY-MM-DD"),
    });
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedLead(null);
  };

  // Pagination logic
  const indexOfLastLead = (currentPage + 1) * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = websiteleads.slice(indexOfFirstLead, indexOfLastLead);

 const handlePageClick = (data) => {
  setCurrentPage(data.selected);
};

console.log("Leads:", currentLeads);
console.log("Assigned Leads:", websiteleadsAssigned);

const handleRefreshLeads = () => {
  setRefreshLeads(!refreshLeads);
  window.location.reload();

  // Toggle state to trigger re-fetch
};

  return (
    <>
      <div className="container 2xl:w-[95%]">
      <div className="flex gap-2">
        <button
          className="bg-blue-500 text-white py-2 px-3 rounded mb-4"
          onClick={() => {
            setShowWebsiteApi(!showWebsite);        // Toggle Add Form visibility
            setShowUpdateWebsiteApi(false);      // Hide Edit/Delete Form if active
          }}
        >
          Add Website Api
        </button>
        
        <button
          className="bg-orange-500 text-white py-2 px-3 rounded mb-4"
          onClick={() => {
            setShowUpdateWebsiteApi(!showUpdateWebsiteApi);  // Toggle Edit/Delete Form visibility
            setShowWebsiteApi(false);                  // Hide Add Form if active
          }}
        >
          Edit And Delete Website Api
        </button>
      </div>
      {showWebsite && <AddFormApi setShowForm={setShowWebsiteApi} onFormSubmit={handleRefreshLeads} />}
      {showUpdateWebsiteApi && <UpdateFormApi />}



        <h1 className="text-2xl text-center mt-[2rem]">Website Leads Data</h1>
        <div className="mx-auto h-[3px] w-16 bg-[#34495E] my-3"></div>

        <div className="overflow-x-auto mt-4">
          <table className="container bg-white border">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 tracking-wider">
                  S.no
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 tracking-wider">
                  Lead Number
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 tracking-wider">
                  Email Id
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 tracking-wider">
                  Phone
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 tracking-wider">
                  Subject
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 tracking-wider">
                 Address
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 tracking-wider">
                  Created Time
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 tracking-wider">
                  Assigned Lead
                </th>
              </tr>
            </thead>
            <tbody>
            {websiteleads?.filter(
  (lead) =>
    !websiteleadsAssigned.some((assigned) => assigned.lead_no === lead.id.toString())
).length === 0 ? (
  // Display "No data found" message when there are no leads
  <tr>
    <td colSpan="9" className="text-center py-4 text-gray-500">
      No data found Please Check Api
    </td>
  </tr>
) : (
  // Display leads if data is available
  websiteleads
    .filter(
      (lead) =>
        !websiteleadsAssigned.some((assigned) => assigned.lead_no === lead.id.toString())
    )
    .map((lead, index) => (
      <tr key={lead.id} className={index % 2 === 0 ? "bg-gray-100" : ""}>
        <td className="px-6 py-4 border-b border-gray-200 text-gray-800">{index + 1}</td>
        <td className="px-6 py-4 border-b border-gray-200">{lead.id}</td>
        <td className="px-6 py-4 border-b border-gray-200 text-gray-800">{lead.name}</td>
        <td className="px-6 py-4 border-b border-gray-200 text-gray-800">{lead.email}</td>
        <td className="px-6 py-4 border-b border-gray-200 text-gray-800">{lead.mobile_no}</td>
        <td className="px-6 py-4 border-b border-gray-200 text-gray-800">{lead.subject}</td>
        <td className="px-6 py-4 border-b border-gray-200 text-gray-800">{lead.address}</td>
        <td className="px-6 py-4 border-b border-gray-200 text-gray-800">
          {moment(lead.created_date).format('DD-MM-YYYY')}
        </td>
        <td className="px-6 py-4 border-b border-gray-200 text-gray-800">
          <button className="text-blue-500 hover:text-blue-700" onClick={() => handleEditClick(lead)}>
            Assign
          </button>
        </td>
      </tr>
    ))
)}


</tbody>

          </table>

    
        </div>

        {/* Popup */}
        {showPopup && selectedLead && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="w-full max-w-md p-6 mx-2 bg-white rounded-lg shadow-lg h-[95%] overflow-y-auto">
            <h2 className="text-xl mb-4">{"Add Lead"}</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Lead Number</label>
              <input
                type="number"
                name="lead_no"
                value={selectedLead.leadId}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border  rounded`}
                disabled
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Assigned To</label>
              <select
                name="assignedTo"
                value={currentLead.assignedTo}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border rounded`}
              >
                <option value="">Select Employee</option>
                {employees.map((employee) => (
                  <option key={employee.employee_id} value={employee.name}>
                    {employee.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Hidden employeeId field */}
            <input
              type="hidden"
              id="employeeId"
              name="employeeId"
              value={currentLead.employeeId}
            />

            <div className="mb-4">
              <label className="block text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                value={selectedLead.fullName}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border  rounded`}
                disabled
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Phone</label>
              <input
                type="text"
                name="phone"
                value={selectedLead.phoneNumber}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border rounded`}
                disabled
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Lead Source</label>
              <select
                name="leadSource"
                id="leadSource"
                value={currentLead.leadSource}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                disabled
              >
                <option value="Website Inquiries">Website</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Subject</label>
              <input
                type="text"
                name="subject"
                value={selectedLead.subject}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border  rounded`}
                disabled
              />
            </div>
            <div className="mb-4">
                  <label className="block text-gray-700">Address</label>
                  <input
                    type="text"
                    name="address"
                    value={selectedLead.address}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border rounded`}
                    disabled
                  />
                  
                </div>
                <div className="mb-4">
              <label className="block text-gray-700">Assign Date</label>
              <input
                type="date"
                name="createdTime"
                value={currentLead.createdTime}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border  rounded`}
                
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Actual Date</label>
              <input
                type="date"
                name="actual_date"
                value={selectedLead.date}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border  rounded`}
                disabled
              />
            </div>


            <div className="flex justify-end">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 mr-2"
                onClick={saveChanges}
              >
                Save
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                onClick={closePopup}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      </div>
    </>
  );
}

export default WebsiteLeads;
