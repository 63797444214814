import React from "react";
import Logout from "../../components/Logout";
import UserLogin from "../../components/UserLogin";

function SuperHeader({ toggleSidebar }) {
  // Accept toggleSidebar as a prop
  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                onClick={toggleSidebar} // Call toggleSidebar when button is clicked
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <img
                src="https://t3.ftcdn.net/jpg/05/38/30/98/360_F_538309859_lrY7wR1QGZbc4Ka4LQE7t2wl623jcPG7.jpg"
                className="mr-3 h-6 sm:h-9"
                alt="Flowbite Logo"
              />
              CRM GURU
            </div>

            <div className="flex items-center lg:order-2 mx-3">
              <div className="mx-4">
                <UserLogin />
              </div>

              <div>
                <Logout />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default SuperHeader;
